// icons
import { Button, Container, Grid, Stack, Typography } from '@mui/material';
// @mui
import { alpha, styled } from '@mui/material';
import { ImageUrlBuilder, useNextSanityImage } from 'next-sanity-image';
import NextLink from 'next/link';
import Routes from 'src/routes';
// utils
import cssStyles from '../../utils/cssStyles';
import { getClient } from 'src/lib/sanity.client';

// ----------------------------------------------------------------------

const RootStyle = styled('div')<{ src: string }>(({ theme, src }) => ({
  padding: theme.spacing(14, 0),
  ...cssStyles(theme).bgImage({
    url: src,
    startColor: `${alpha(theme.palette.grey[900], 0.88)}`,
    endColor: `${alpha(theme.palette.grey[900], 0.88)}`,
  }),
  [theme.breakpoints.up('md')]: {
    ...cssStyles(theme).bgImage({
      direction: 'right',
      url: src,
      startColor: `${alpha(theme.palette.grey[900], 0)} 0%`,
      endColor: `${alpha(theme.palette.grey[900], 1)} 50%`,
    }),
    backgroundPosition: 'center, left ',
    backgroundSize: 'cover, 50%',
  },
}));

// ----------------------------------------------------------------------

export default function Newsletter() {
  const ImageBuilder = (imageUrlBuilder: ImageUrlBuilder) => {
    return imageUrlBuilder.width(750).auto('format').quality(70);
  };

  const image = useNextSanityImage(
    getClient(),
    'image-4210ac8f6dda085e822fca683bb776b4c599314f-3840x2560-jpg',
    { imageBuilder: ImageBuilder }
  ).src;

  return (
    <RootStyle src={image || ''}>
      <Container>
        <Grid container spacing={3} justifyContent={{ md: 'flex-end', xs: 'center' }}>
          <Grid item xs={12} md={5}>
            <Stack
              spacing={3}
              sx={{
                color: 'common.white',
                textAlign: { xs: 'center', md: 'left' },
                alignItems: { xs: 'center', md: 'start' },
              }}
            >
              <Typography variant="h2">Kontakt oss</Typography>
              <Typography>
                Vi er den punktlige elektrikeren som utfører oppdrag for både private og bedrifter i
                hele Bergensområdet.
              </Typography>

              <Stack direction="row" spacing={3}>
                <NextLink href={Routes.form} passHref legacyBehavior>
                  <Button variant="contained" size="large" sx={{ minWidth: 48 }}>
                    Bestill befaring
                  </Button>
                </NextLink>
                <NextLink href={Routes.contact} passHref legacyBehavior>
                  <Button variant="outlined" color="inherit" size="large" sx={{ minWidth: 48 }}>
                    Kontaktside
                  </Button>
                </NextLink>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
